export const defaultMessageTemplate = `Hi <%= contact.firstName || "there"; %>!
<br>
<br>
Quick pop quiz! Just click the right answer above to show off your smarts. 🌟
<br>
<br>
Why? Because it helps you <i>actually</i> remember stuff. 🧠💪
<br>
<br>
Stay awesome and sharp!<br>
<b>Team Reteyn</b><br>
<br>
PS: Got feedback? Just hit reply — we're all ears! 🙏
`;
