import {
  CredentialsAndIdentityId,
  CredentialsAndIdentityIdProvider,
  GetCredentialsOptions,
} from "@aws-amplify/core";
import { JwtPayload } from "@aws-amplify/core/internals/utils";
import { CognitoIdentity } from "@aws-sdk/client-cognito-identity";

export class PasswordlessCredentialProvider
  implements CredentialsAndIdentityIdProvider
{
  constructor(
    private credentialCache: Record<
      string,
      Promise<CredentialsAndIdentityId | undefined>
    > = {},
  ) {}

  async createCredentials(
    options: GetCredentialsOptions,
  ): Promise<CredentialsAndIdentityId | undefined> {
    const identityPoolId = options.authConfig?.Cognito.identityPoolId as string;
    const region = identityPoolId.split(":")[0];
    const cognitoidentity = new CognitoIdentity({
      region,
    });

    const baseRequest = {
      Logins: options.authenticated
        ? {
            [this.toLoginDomain(options.tokens.idToken?.payload as JwtPayload)]:
              options.tokens.idToken?.toString() as string,
          }
        : {},
    };
    const getIdResult = await cognitoidentity.getId({
      ...baseRequest,
      IdentityPoolId: identityPoolId,
    });
    const identityId = getIdResult.IdentityId;
    const cognitoCredentialsResult =
      await cognitoidentity.getCredentialsForIdentity({
        ...baseRequest,
        IdentityId: identityId,
      });
    return {
      credentials: {
        accessKeyId: cognitoCredentialsResult.Credentials
          ?.AccessKeyId as string,
        secretAccessKey: cognitoCredentialsResult.Credentials
          ?.SecretKey as string,
        sessionToken: cognitoCredentialsResult.Credentials?.SessionToken,
        expiration: cognitoCredentialsResult.Credentials?.Expiration,
      },
      identityId,
    };
  }

  getCredentialsAndIdentityId(
    options: GetCredentialsOptions,
  ): Promise<CredentialsAndIdentityId | undefined> {
    const key = JSON.stringify(options);
    this.credentialCache[key] =
      this.credentialCache[key] || this.createCredentials(options);
    return this.credentialCache[key];
  }

  toLoginDomain(idToken: JwtPayload): string {
    const url = new URL(idToken.iss as string);
    return `${url.hostname}${url.pathname}`;
  }

  clearCredentialsAndIdentityId(): void {
    this.credentialCache = {};
  }
}
